// extracted by mini-css-extract-plugin
export var agreementCheck = "ProviderTile__agreementCheck__x9uVz";
export var badgeWrapper = "ProviderTile__badgeWrapper__ozf1t";
export var column = "ProviderTile__column__Jr55M";
export var editIcon = "ProviderTile__editIcon__KBZAC";
export var flex = "ProviderTile__flex__JzTDm";
export var flexColumn = "ProviderTile__flexColumn__MkKob";
export var gap1 = "ProviderTile__gap1__hVhWe";
export var gap2 = "ProviderTile__gap2__JxGHU";
export var gap3 = "ProviderTile__gap3__SKPhH";
export var gap4 = "ProviderTile__gap4__nFrlY";
export var gap5 = "ProviderTile__gap5__clY_E";
export var inactive = "ProviderTile__inactive__d8rS2";
export var item = "ProviderTile__item__c6HnL";
export var itemContainer = "ProviderTile__itemContainer__pdQbo";
export var itemsList = "ProviderTile__itemsList__vVwMa";
export var leftBadgeWrapper = "ProviderTile__leftBadgeWrapper__G9HBF";
export var logo = "ProviderTile__logo__HmVCQ";
export var logoContainer = "ProviderTile__logoContainer__eNBSk";
export var manageTileHeight = "ProviderTile__manageTileHeight__Re8Xi";
export var row = "ProviderTile__row__OXdUE";
export var total = "ProviderTile__total__QjNfl";
export var wrapper = "ProviderTile__wrapper__IQql8";