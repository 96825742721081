// Generated by ReScript, PLEASE EDIT WITH CARE

import * as A from "../../../styleguide/components/Link/A.res.js";
import * as P from "../../../styleguide/components/Paragraph/P.res.js";
import * as H1 from "../../../styleguide/components/Heading/H1.res.js";
import * as H2 from "../../../styleguide/components/Heading/H2.res.js";
import * as H3 from "../../../styleguide/components/Heading/H3.res.js";
import * as $$String from "rescript/lib/es6/string.js";
import * as Routes_User from "../../../routes/common/Routes_User.res.js";
import * as Routes_Project from "../../../routes/common/Routes_Project.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as Routes_Marketplace from "../../../routes/common/Routes_Marketplace.res.js";
import * as ProvidersListScss from "../ProvidersList.scss";

var css = ProvidersListScss;

var year = new Date().getFullYear();

var Helpers = {
  year: year
};

function ProvidersListSeo(props) {
  var match = $$String.lowercase_ascii(props.serviceCategory);
  var tmp;
  switch (match) {
    case "bare metal" :
        tmp = JsxRuntime.jsxs(JsxRuntime.Fragment, {
              children: [
                JsxRuntime.jsxs("section", {
                      children: [
                        JsxRuntime.jsx(H1.make, {
                              children: String(year) + " TOP BARE METAL PROVIDERS"
                            }),
                        JsxRuntime.jsxs(P.make, {
                              children: [
                                "Searching for top bare metal providers? ",
                                JsxRuntime.jsx(A.make, {
                                      href: "/",
                                      children: "Datacenters.com"
                                    }),
                                " has compiled a list of our top global bare metal service providers for" + (String(year) + " based on our in-depth research, industry knowledge and first-hand experience working with these providers directly.")
                              ]
                            })
                      ]
                    }),
                JsxRuntime.jsxs("section", {
                      children: [
                        JsxRuntime.jsx(H2.make, {
                              children: "VIEW PROFILES, BARE METAL COMPANIES"
                            }),
                        JsxRuntime.jsxs(P.make, {
                              children: [
                                "Click on any of the bare metal provider logos or links above to visit their company profile,\n                    view data center facilities, and products and services listed in the ",
                                JsxRuntime.jsx(A.make, {
                                      href: Routes_Marketplace.colocation,
                                      children: "Datacenters.com Marketplace"
                                    }),
                                ". ",
                                JsxRuntime.jsx(A.make, {
                                      href: "/",
                                      children: "Datacenters.com"
                                    }),
                                " provides access to all major data center companies both Domestically (USA) and Internationally."
                              ]
                            })
                      ]
                    }),
                JsxRuntime.jsxs("section", {
                      children: [
                        JsxRuntime.jsx(H3.make, {
                              children: "PARTNERSHIP AGREEMENTS WITH BARE METAL PROVIDERS"
                            }),
                        JsxRuntime.jsxs(P.make, {
                              children: [
                                JsxRuntime.jsx(A.make, {
                                      href: "/",
                                      children: "Datacenters.com"
                                    }),
                                " has partnerships with all major bare metal providers listed above. We provide direct access to these providers for ",
                                JsxRuntime.jsx(A.make, {
                                      href: Routes_Project.BareMetal.$$new,
                                      children: "bare metal pricing"
                                    }),
                                ", data center site tours, support, and technical questions.\n                    Check out our digital bare metal RFP tool now!"
                              ]
                            })
                      ]
                    })
              ]
            });
        break;
    case "cloud" :
        tmp = JsxRuntime.jsxs(JsxRuntime.Fragment, {
              children: [
                JsxRuntime.jsxs("section", {
                      children: [
                        JsxRuntime.jsx(H1.make, {
                              children: String(year) + " TOP CLOUD PROVIDERS"
                            }),
                        JsxRuntime.jsxs(P.make, {
                              children: [
                                "Searching for top cloud service providers offering cloud servers and cloud storage? ",
                                JsxRuntime.jsx(A.make, {
                                      href: "/",
                                      children: "Datacenters.com"
                                    }),
                                " has compiled a list of our top cloud providers for " + (String(year) + " based on our in-depth research, industry knowledge and first-hand experience\n                       working with these providers directly.")
                              ]
                            })
                      ]
                    }),
                JsxRuntime.jsxs("section", {
                      children: [
                        JsxRuntime.jsx(H2.make, {
                              children: "VIEW PROFILES OF TOP CLOUD PROVIDERS"
                            }),
                        JsxRuntime.jsx(P.make, {
                              children: "Click on any of the cloud providers above to visit their company profile, view data centers,\n                    availability zones, regions and cloud services. We provide access to all major cloud\n                    providers in the USA and Internationally."
                            })
                      ]
                    }),
                JsxRuntime.jsxs("section", {
                      children: [
                        JsxRuntime.jsx(H3.make, {
                              children: "PARTNERSHIP AGREEMENTS WITH CLOUD SERVICE PROVIDERS"
                            }),
                        JsxRuntime.jsxs(P.make, {
                              children: [
                                JsxRuntime.jsx(A.make, {
                                      href: "/",
                                      children: "Datacenters.com"
                                    }),
                                " has partnerships with all major cloud service providers listed above. We provide direct access\n                   to these providers for ",
                                JsxRuntime.jsx(A.make, {
                                      href: Routes_Project.Cloud.$$new,
                                      children: "cloud server pricing"
                                    }),
                                ", cloud storage pricing, and other services."
                              ]
                            }),
                        JsxRuntime.jsxs(P.make, {
                              children: [
                                "Want to become a partner and get listed on Datacenters.com? Click to learn more about our ",
                                JsxRuntime.jsx(A.make, {
                                      href: Routes_User.signUp,
                                      children: "Datacenters.com Provider Program"
                                    }),
                                "."
                              ]
                            })
                      ]
                    })
              ]
            });
        break;
    case "colocation" :
        tmp = JsxRuntime.jsxs(JsxRuntime.Fragment, {
              children: [
                JsxRuntime.jsxs("section", {
                      children: [
                        JsxRuntime.jsx(H1.make, {
                              children: String(year) + " TOP COLOCATION PROVIDERS"
                            }),
                        JsxRuntime.jsxs(P.make, {
                              children: [
                                "Searching for top colocation providers and their data center locations.",
                                JsxRuntime.jsx(A.make, {
                                      href: "/",
                                      children: "Datacenters.com"
                                    }),
                                " has compiled a list of our top global colocation service providers for" + (String(year) + " based on our in-depth research, industry knowledge and first-hand experience working with these providers directly.")
                              ]
                            })
                      ]
                    }),
                JsxRuntime.jsxs("section", {
                      children: [
                        JsxRuntime.jsx(H2.make, {
                              children: "VIEW PROFILES, COLOCATION COMPANIES"
                            }),
                        JsxRuntime.jsxs(P.make, {
                              children: [
                                "Click on any of the colocation provider logos or links above to visit their company profile,\n                    view data center facilities, and products and services listed in the ",
                                JsxRuntime.jsx(A.make, {
                                      href: Routes_Marketplace.colocation,
                                      children: "Datacenters.com Marketplace"
                                    }),
                                ". ",
                                JsxRuntime.jsx(A.make, {
                                      href: "/",
                                      children: "Datacenters.com"
                                    }),
                                " provides access to all major data center companies both Domestically (USA) and Internationally."
                              ]
                            })
                      ]
                    }),
                JsxRuntime.jsxs("section", {
                      children: [
                        JsxRuntime.jsx(H3.make, {
                              children: "PARTNERSHIP AGREEMENTS WITH COLOCATION PROVIDERS"
                            }),
                        JsxRuntime.jsxs(P.make, {
                              children: [
                                JsxRuntime.jsx(A.make, {
                                      href: "/",
                                      children: "Datacenters.com"
                                    }),
                                " has partnerships with all major colocation providers listed above. We provide direct access to these providers for ",
                                JsxRuntime.jsx(A.make, {
                                      href: Routes_Project.Colocation.$$new,
                                      children: "colocation pricing"
                                    }),
                                ", data center site tours, support, and technical questions.\n                    Check out our digital colocation RFP tool now!"
                              ]
                            }),
                        JsxRuntime.jsxs(P.make, {
                              children: [
                                "Want to become a partner and get listed on Datacenters.com? Click to learn more about our ",
                                JsxRuntime.jsx(A.make, {
                                      href: Routes_User.signUp,
                                      children: "Datacenters.com Provider Program"
                                    }),
                                "."
                              ]
                            })
                      ]
                    })
              ]
            });
        break;
    case "internet" :
        tmp = JsxRuntime.jsxs(JsxRuntime.Fragment, {
              children: [
                JsxRuntime.jsxs("section", {
                      children: [
                        JsxRuntime.jsx(H1.make, {
                              children: String(year) + " TOP INTERNET PROVIDERS"
                            }),
                        JsxRuntime.jsxs(P.make, {
                              children: [
                                "Searching for the top internet providers, broadband and satellite internet providers for\n                   high speed internet access? ",
                                JsxRuntime.jsx(A.make, {
                                      href: "/",
                                      children: "Datacenters.com"
                                    }),
                                " offers access to the best internet service providers (ISPs) and pricing for fast internet\n                   and gigabit internet including speeds up to 40 gigabits per second (Gbps). Get side-by-side\n                   comparisons of multiple ISPs and telecom carriers for your business locations now!"
                              ]
                            })
                      ]
                    }),
                JsxRuntime.jsxs("section", {
                      children: [
                        JsxRuntime.jsx(H2.make, {
                              children: "VIEW PROFILES, INTERNET SERVICES AND PRODUCTS"
                            }),
                        JsxRuntime.jsx(P.make, {
                              children: "Click on any of the internet service providers above to visit their company profile page\n                   and view associated internet service offerings, on-net carrier lists and fiber route\n                   information. We provide access to all the major internet providers both domestically and\n                   internationally."
                            })
                      ]
                    }),
                JsxRuntime.jsxs("section", {
                      children: [
                        JsxRuntime.jsx(H3.make, {
                              children: "PARTNERSHIP AGREEMENTS WITH INTERNET SERVICE PROVIDERS"
                            }),
                        JsxRuntime.jsxs(P.make, {
                              children: [
                                JsxRuntime.jsx(A.make, {
                                      href: "/",
                                      children: "Datacenters.com"
                                    }),
                                " provides access to top business internet providers like AT&T Business, Verizon Business,\n                   CenturyLink Business, COX Business, Spectrum Business, euNetworks, BT, NTT, Telstra, Tata\n                   Communications and 200+ others globally."
                              ]
                            }),
                        JsxRuntime.jsx(P.make, {
                              children: "We have partnership agreements in place with all the providers listed on our platform and\n                   can provide direct access to these ISPs for internet pricing and quotes, support and\n                   technical questions about network, internet speeds, reliability and performance."
                            }),
                        JsxRuntime.jsxs(P.make, {
                              children: [
                                "Want to become a partner and get listed on Datacenters.com? Click to learn more about our ",
                                JsxRuntime.jsx(A.make, {
                                      href: Routes_User.signUp,
                                      children: "Datacenters.com Provider Program"
                                    }),
                                "."
                              ]
                            })
                      ]
                    })
              ]
            });
        break;
    case "network" :
        tmp = JsxRuntime.jsxs(JsxRuntime.Fragment, {
              children: [
                JsxRuntime.jsxs("section", {
                      children: [
                        JsxRuntime.jsx(H1.make, {
                              children: String(year) + " TOP NETWORK SERVICE PROVIDERS"
                            }),
                        JsxRuntime.jsxs(P.make, {
                              children: [
                                "Searching for the top network service providers for SD-WAN, MPLS, CDN, private line and\n                   other network services? ",
                                JsxRuntime.jsx(A.make, {
                                      href: "/",
                                      children: "Datacenters.com"
                                    }),
                                " offers access to the best network providers and pricing for global, wide area networking (WAN)\n                   solutions from multiple ISPs, telecom carriers and network providers. Get side-by-side comparisons for\n                   proposals in the Datacenters.com CloudRack™ RFP Platform."
                              ]
                            })
                      ]
                    }),
                JsxRuntime.jsxs("section", {
                      children: [
                        JsxRuntime.jsx(H2.make, {
                              children: "VIEW PROFILES, NETWORK SERVICES AND PRODUCTS"
                            }),
                        JsxRuntime.jsx(P.make, {
                              children: "Click on any of the network service providers above to visit their company profile page\n                   and view associated networking solutions, on-net building lists and fiber route information.\n                   We provide direct access to all the major network service providers both Domestically and\n                   Internationally."
                            })
                      ]
                    }),
                JsxRuntime.jsxs("section", {
                      children: [
                        JsxRuntime.jsx(H3.make, {
                              children: "PARTNERSHIP AGREEMENTS WITH NETWORK PROVIDERS"
                            }),
                        JsxRuntime.jsxs(P.make, {
                              children: [
                                JsxRuntime.jsx(A.make, {
                                      href: "/",
                                      children: "Datacenters.com"
                                    }),
                                " provides access to top network providers like AT&T Business, Verizon Business,\n                   CenturyLink Business, Masergy, Granite, BT, euNetworks, NTT, Telstra, Tata Communications\n                   and 200+ other providers globally."
                              ]
                            }),
                        JsxRuntime.jsx(P.make, {
                              children: "We have partnership agreements in place with all the providers listed on our platform and\n                   can provide direct access to these NSPs for WAN pricing and quotes, support and technical\n                   questions about network services, speeds, cost, reliability and performance."
                            }),
                        JsxRuntime.jsxs(P.make, {
                              children: [
                                "Want to become a partner and get listed on Datacenters.com? Click to learn more about our ",
                                JsxRuntime.jsx(A.make, {
                                      href: Routes_User.signUp,
                                      children: "Datacenters.com Provider Program"
                                    }),
                                "."
                              ]
                            })
                      ]
                    })
              ]
            });
        break;
    default:
      tmp = JsxRuntime.jsxs(JsxRuntime.Fragment, {
            children: [
              JsxRuntime.jsxs("section", {
                    children: [
                      JsxRuntime.jsx(H1.make, {
                            children: String(year) + " TOP DATA CENTER PROVIDERS"
                          }),
                      JsxRuntime.jsxs(P.make, {
                            children: [
                              "Searching for the top data center providers, colocation providers, cloud service providers,\n                   disaster recovery service providers, or telecommunications companies? ",
                              JsxRuntime.jsx(A.make, {
                                    href: "/",
                                    children: "Datacenters.com"
                                  }),
                              " has compiled a list of our top providers for " + (String(year) + " based on in-depth research and firsthand knowledge and experience working with providers directly.")
                            ]
                          })
                    ]
                  }),
              JsxRuntime.jsxs("section", {
                    children: [
                      JsxRuntime.jsx(H2.make, {
                            children: "VIEW PROFILES, DATA CENTERS AND PRODUCTS"
                          }),
                      JsxRuntime.jsxs(P.make, {
                            children: [
                              "Click on any of the provider links above to visit their company profile page, view associated data center\n                   locations and facilities, and view products and services listed in the ",
                              JsxRuntime.jsx(A.make, {
                                    href: Routes_Marketplace.colocation,
                                    children: "Datacenters.com Marketplace"
                                  }),
                              ". We provide access to all major data center companies, Domestic (USA) and International."
                            ]
                          })
                    ]
                  }),
              JsxRuntime.jsxs("section", {
                    children: [
                      JsxRuntime.jsx(H3.make, {
                            children: "PARTNERSHIP AGREEMENTS WITH DATA CENTER PROVIDERS"
                          }),
                      JsxRuntime.jsxs(P.make, {
                            children: [
                              JsxRuntime.jsx(A.make, {
                                    href: "/",
                                    children: "Datacenters.com"
                                  }),
                              " has partnership agreements with major data center providers listed above. We provide direct access\n                   to these providers for ",
                              JsxRuntime.jsx(A.make, {
                                    href: Routes_Project.Colocation.$$new,
                                    children: "data center pricing and quotes"
                                  }),
                              ", support, and technical questions."
                            ]
                          }),
                      JsxRuntime.jsxs(P.make, {
                            children: [
                              "Want to become a partner and get listed on Datacenters.com? Click to learn more about our ",
                              JsxRuntime.jsx(A.make, {
                                    href: Routes_User.signUp,
                                    children: "Datacenters.com Provider Program"
                                  }),
                              "."
                            ]
                          })
                    ]
                  })
            ]
          });
  }
  return JsxRuntime.jsx("div", {
              children: JsxRuntime.jsx("div", {
                    children: JsxRuntime.jsx("div", {
                          children: tmp,
                          className: css.seoText
                        }),
                    className: css.seoTextContainer
                  }),
              className: css.seoContainer
            });
}

var make = ProvidersListSeo;

export {
  css ,
  Helpers ,
  make ,
}
/* css Not a pure module */
